import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import SmallHeader from "../components/SmallHeader"
import { Col, Container, Row } from "reactstrap"
import TitleBlock from "../components/blocks/TitleBlock"
import ContactInfo from "../components/general/ContactInfo"
import { graphql, useStaticQuery } from "gatsby"

const Cennik = () => {
  const images = useStaticQuery(graphql`
    {
      cover: file(
        name: { eq: "general-page-background" }
        relativeDirectory: { eq: "headers" }
      ) {
        id
        childImageSharp {
          fluid(quality: 80) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    }
  `)

  return (
    <Layout>
      <SEO title="Cennik 2024" />
      <SmallHeader title="Cennik" backgroundName={images.cover} />

      <Container>
        <Row>
          <Col xs="12" md="12" className="mr-auto ml-auto">
            <div className="text-center section">
              <TitleBlock
                title="Cennik na sezon 2024"
                headingLevel="h3"
                extendedClasses="color-sea font-weight-bold"
              />
            </div>
          </Col>
        </Row>

        <Row>
          <Col xs="12" md="8" className="mr-auto ml-auto">
            <div className={`table-responsive`}>
              <table className={`table price-table`}>
                <thead>
                  <tr>
                    <th></th>
                    <th>
                      29.05 - 30.06 oraz 01.09 - 15.09
                    </th>
                    <th>01.07 - 31.08</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>Apartament 2-osobowy</td>
                    <td className={`text-center`}>230PLN/noc</td>
                    <td className={`text-center`}>290PLN/noc</td>
                  </tr>

                  <tr>
                    <td>Apartament 3-osobowy</td>
                    <td className={`text-center`}>260PLN/noc</td>
                    <td className={`text-center`}>330PLN/noc</td>
                  </tr>

                  <tr>
                    <td>Apartament 3-osobowy (dwupokojowy)</td>
                    <td className={`text-center`}>300PLN/noc</td>
                    <td className={`text-center`}>360PLN/noc</td>
                  </tr>

                  <tr>
                    <td>Apartament 4-osobowy (dwupokojowy)</td>
                    <td className={`text-center`}>360PLN/noc</td>
                    <td className={`text-center`}>430PLN/noc</td>
                  </tr>

                  <tr>
                    <td>Apartament 5-osobowy (dwupokojowy)</td>
                    <td className={`text-center`}>400PLN/noc</td>
                    <td className={`text-center`}>470PLN/noc</td>
                  </tr>

                  <tr>
                    <td>
                      Miejsce garażowe (konieczna wcześniejsza rezerwacja)
                    </td>
                    <td className={`text-center`}>10PLN/noc</td>
                    <td className={`text-center`}>20PLN/noc</td>
                  </tr>

                  <tr>
                    <td>
                      Miejsce parkingowe (konieczna wcześniejsza rezerwacja)
                    </td>
                    <td className={`text-center`}>bezpłatne</td>
                    <td className={`text-center`}>10PLN/noc</td>
                  </tr>
                  <tr>
                    <td>Dostawka dla dziecka 0-3 lat</td>
                    <td colSpan={`2`} className={`text-center`}>
                      bezpłatnie
                    </td>
                  </tr>
                  <tr>
                    <td>Dostawka dla dzieci powyżej 4 lat i dorosłych</td>
                    <td colSpan={`2`} className={`text-center`}>
                      50PLN/noc
                    </td>
                  </tr>
                  <tr>
                    <td>
                      Łóżeczko turystyczne dziecięce z pościelą (konieczna
                      wcześniejsza rezerwacja)
                    </td>
                    <td colSpan={`2`} className={`text-center`}>
                      50zł (jednorazowo)
                    </td>
                  </tr>
                  <tr>
                    <td>
                      Fotelik do karmienia, wanienka (konieczna wcześniejsza
                      rezerwacja)
                    </td>
                    <td colSpan={`2`} className={`text-center`}>
                      bezpłatnie
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>

            <Row>
              <Col md="12">
                <p className="pt-5">
                  <span className="color-sea font-weight-bold">
                    Doba hotelowa:
                  </span>{" "}
                  rozpoczyna się o godzinie 16:00 pierwszego dnia pobytu i
                  kończy o 10:00 ostatniego dnia pobytu.
                </p>
              </Col>
            </Row>

            <Row>
              <Col md="12">
                <p className="pt-5 font-weight-bold">
                  W naszym obiekcie nie ma możliwości pobytu ze zwierzętami.
                </p>
              </Col>
            </Row>
          </Col>
        </Row>

        <Row>
          <Col xs="12" md="12" className="ml-auto mr-auto">
            <div className="section">
              <ContactInfo />
            </div>
          </Col>
        </Row>
      </Container>
    </Layout>
  )
}

export default Cennik
